import Link from "next/link";
import styles from "./button.module.css";

const Button = ({ title, style, link = "/", onClick, noLink = false }) => {
  return (
    <>
      {noLink && (
        <button className={`${styles.button} ${style}`} onClick={onClick}>
          {title}
        </button>
      )}
      {!noLink && (
        <Link href={link}>
          <button className={`${styles.button} ${style}`} onClick={onClick}>
            {title}
          </button>
        </Link>
      )}
    </>
  );
};

export default Button;
