import(/* webpackMode: "eager" */ "/vercel/path0/app/globals.css");
import(/* webpackMode: "eager" */ "/vercel/path0/app/GoogleAnalytics.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/header/header.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/components/footer/footer.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/components/ui/footer-svg/footer-svg.js");
import(/* webpackMode: "eager" */ "/vercel/path0/components/ui/form-footer/form-footer.js");
import(/* webpackMode: "eager" */ "/vercel/path0/components/ui/header-ui/header-iu.js");
import(/* webpackMode: "eager" */ "/vercel/path0/components/ui/image-header/image-header.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/layout.js\",\"import\":\"\",\"arguments\":[{\"src\":\"./local-fonts/Spartan-Bold.ttf\",\"weight\":\"700\",\"style\":\"normal\",\"variable\":\"--font-bold\"}],\"variableName\":\"myFontBold\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/layout.js\",\"import\":\"\",\"arguments\":[{\"src\":\"./local-fonts/Spartan-SemiBold.ttf\",\"weight\":\"600\",\"style\":\"normal\",\"variable\":\"--font-semibold\"}],\"variableName\":\"myFontSemibold\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/layout.js\",\"import\":\"\",\"arguments\":[{\"src\":\"./local-fonts/Spartan-Medium.ttf\",\"weight\":\"500\",\"style\":\"normal\",\"variable\":\"--font-medium\"}],\"variableName\":\"myFontMedium\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/layout.js\",\"import\":\"\",\"arguments\":[{\"src\":\"./local-fonts/Spartan-Regular.ttf\",\"weight\":\"400\",\"style\":\"normal\",\"variable\":\"--font-spartan-regular\"}],\"variableName\":\"spartanRegular\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/layout.js\",\"import\":\"\",\"arguments\":[{\"src\":\"./local-fonts/SourceSansPro-Regular.otf\",\"weight\":\"400\",\"style\":\"normal\",\"variable\":\"--font-regular\"}],\"variableName\":\"myFontRegular\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/layout.js\",\"import\":\"\",\"arguments\":[{\"src\":\"./local-fonts/SourceSansPro-Semibold.otf\",\"weight\":\"600\",\"style\":\"normal\",\"variable\":\"--font-secondary-semibold\"}],\"variableName\":\"mySecondFontSemibold\"}")