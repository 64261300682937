"use client";

import { useEffect, useState } from "react";

import Button from "../button/button";
import { HiOutlineMenu } from "react-icons/hi";
import Image from "next/image";
import { IoClose } from "react-icons/io5";
import Link from "next/link";
import { motion } from "framer-motion";
import styles from "./header-ui.module.css";

const MenuMobile = () => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [width, setWidth] = useState()

  const handleMenu = () => {
    setIsMenuOpened(!isMenuOpened);
  };

  useEffect(() => {
    const width = window.innerWidth;
    width > 539 && setIsMenuOpened(true);
    setWidth(width)
  }, []);
  return (
    <>
      <div
        className={styles.menuContainer}
        style={{ display: isMenuOpened ? "flex" : "none" }}
      >
        <div className={styles.topOptions}>
          <div className={styles.logoMenu}>
            <Link href={"/"}>
              <Image
                src={"/images/logo-icp2.png"}
                alt="logo"
                width={100}
                height={100}
              />
            </Link>
          </div>
          <div className={styles.closeMenu} onClick={handleMenu}>
            <IoClose size={32} color="#F28D73" />
          </div>
        </div>

        <ul className={styles.menu}>
          <motion.li whileHover={{color: '#6F959D', scale: 1.1}}>
            <Link href={"/#evento"} onClick={() => width < 540 && handleMenu()}>Sobre el evento</Link>
          </motion.li>
          <motion.li whileHover={{color: '#6F959D', scale: 1.1}}>
            <Link href={"/#disertantes"} onClick={() => width < 540 && handleMenu()}>Disertantes</Link>
          </motion.li>
          <motion.li whileHover={{color: '#6F959D', scale: 1.1}}>
            <Link href={"/#organizador"} onClick={() => width < 540 && handleMenu()}>Quienes organizan</Link>
          </motion.li>
          <motion.li whileHover={{color: '#6F959D', scale: 1.1}}>
            <Link href={"/#inscripcion"} onClick={() => width < 540 && handleMenu()}>Inscripción</Link>
          </motion.li>
        </ul>
        <div className={styles.download}>
          <Button title={"Cronograma"} link={"/#formulario"} onClick={() => width < 540 && handleMenu()}/>
        </div>
      </div>
      <div className={styles.menuMobileOpen} onClick={handleMenu}>
        <HiOutlineMenu size={32} color="#F28D73" />
      </div>
    </>
  );
};

export default MenuMobile;
