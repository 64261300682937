"use client";
const { default: Image } = require("next/image");
const { useState, useEffect } = require("react");

const ImageHeader = () => {
  const [mobile, setMobile] = useState(null);

  useEffect(() => {
    const width = window.innerWidth;
    width < 451 ? setMobile(true) : setMobile(false);
  });
  return (
    <>
      {mobile && (
        <Image
          src={"/images/logo-icp2.png"}
          alt="logo"
          width={100}
          height={100}
        />
      )}
      {mobile === false && (
        <Image
          src={"/images/logo-full.png"}
          alt="logo"
          width={229}
          height={133}
        />
      )}
    </>
  );
};

export default ImageHeader;
