"use client";

import styles from "./form-footer.module.css";
import { useState } from "react";

const FormFooter = () => {
  const [user, setUser] = useState({
    name: "",
    phone: "",
    email: "",
  });
  const [error, setError] = useState(null)

  /* const PDF_URL = 'http://localhost:3000/docs/Programa_conversando_con_referentes_2023.pdf' */
  const PDF_URL = 'https://www.eventocoaching.com.ar/docs/Programa_conversando_con_referentes_2023.pdf'

  const downloadFile = (url) => {
    const fileName = url.split('/').pop()
    const aTag = document.createElement('a')
    aTag.href = url
    aTag.setAttribute('download', fileName)
    document.body.appendChild(aTag)
    aTag.click()
    aTag.remove()
  }

  const validEmail =  /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/

  const saveUser = async () => {
    try {

      if (user.name.length === 0) {
        throw new Error('Ingresar tu nombre es requerido')
      }
      if (user.phone.toString().length < 9) {
        throw new Error('Número invalido')
      }
      if (!validEmail.test(user.email)) {
        throw new Error('Correo electrónico invalido')
      }
      const res = await fetch('/api/download', {
        method: 'Post',
        body: JSON.stringify(user),
        headers: {
          "Content-Type": "application/json"
        }
      })

      setUser({
        name: '',
        phone: '',
        email: '',
      })
      setError('')

      downloadFile(PDF_URL)
    } catch (error) {
      console.log(error)
      setError(error.message)
    }
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    await saveUser()
  };

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  return (
    <form className={styles.topForm} onSubmit={handleSubmit}>
      <div className={styles.inputGroup}>
        <input
          type="text"
          name="name"
          value={user.name}
          className={styles.inputText}
          placeholder="Nombre y Apellido"
          onChange={handleChange}
        />
        <input
          type="text"
          name="phone"
          value={user.phone}
          className={styles.inputText}
          placeholder="Teléfono"
          onChange={handleChange}
        />
        <input
          type="text"
          name="email"
          value={user.email}
          className={styles.inputText}
          placeholder="Correo eléctronico"
          onChange={handleChange}
        />
      </div>
      <button className={styles.inputButton}>Descargar</button>
      <span className={styles.error}>{error}</span>
    </form>
  );
};

export default FormFooter;
